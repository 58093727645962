<template>
  <v-collapse-wrapper
    name="section"
    class="mt-12"
    :active="true"
    v-on:onStatusChange="this.$parent.toggleCaret"
  >
    <div
      name="section-header"
      v-collapse-toggle
      class="text-center pt-2 pb-2 rounded-full text-gray-50 font-bold uppercase mb-6"
    >
      Funeral Arrangement
      <span class="caret text-xs">╲╱</span>
    </div>

    <section v-collapse-content>
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2">Burial or memorial location arrangement</div>

        <!-- Row -->
        <div class="">
          <label class="inline-block align-middle"
            ><input
              v-model="locationArragement"
              @input="unsetOtherLocationArrangement"
              value="hometown"
              class="mr-6"
              type="radio"
            />
            To hold at hometown</label
          >
        </div>
        <div class="">
          <label class="inline-block align-middle"
            ><input
              v-model="locationArragement"
              @input="unsetOtherLocationArrangement"
              value="no plans"
              class="mr-6"
              type="radio"
            />
            No plans</label
          >
        </div>

        <!-- Row -->
        <div class="">
          <label class="inline-block align-middle"
            ><input
              v-model="locationArragement"
              @input="unsetOtherLocationArrangement"
              value="current residence"
              class="mr-6"
              type="radio"
            />
            To hold at current residence</label
          >
        </div>
        <div class="">
          <span class="inline-block align-middle">
            <input
              class="border-0 border-b"
              type="text"
              @input="setOtherLocationArrangement"
              ref="otherLocationArragement"
              placeholder="Others"
            />
          </span>
        </div>
      </div>

      <hr class="mt-6 bg-top-c border-yellow-400" />

      <div class="grid grid-cols-3 gap-4 mt-6">
        <div class="col-span-3">Burial or memorial financial arrangement</div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="inline-block align-middle">Savings</span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="savings"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('savings', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="inline-block align-middle">Khairat Kematian</span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="khairatKematian"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('khairatKematian', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="inline-block align-middle">
            <input
              class="border-0 border-b disabled:opacity-50"
              type="text"
              v-model="otherFinancialArrangement"
              :required="otherFinancialArrangementValue !== ''"
              placeholder="Others"
            />
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="otherFinancialArrangementValue"
            :required="otherFinancialArrangement !== ''"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="
              toCurrency('otherFinancialArrangementValue', undefined, $event)
            "
          />
        </div>

        <!-- Row -->
        <div class="col-span-3"></div>
      </div>

      <hr class="mt-6 bg-top-c border-yellow-400" />

      <label class="mt-6 block">Remarks on funeral arrangement</label>
      <textarea
        v-model="remarks"
        class="border-1 rounded w-full h-12"
        style="height: 154px"
      >
      </textarea>
    </section>
  </v-collapse-wrapper>
</template>

<script>
import Vue from "vue";
import utils from "./../utils";

export default Vue.extend({
  props: ["value"],
  data() {
    return {
      // form
      locationArragement: "",
      savings: "",
      khairatKematian: "",
      otherFinancialArrangement: "",
      otherFinancialArrangementValue: "",
      remarks: ""
    };
  },
  methods: {
    setOtherLocationArrangement(e) {
      this.locationArragement = e.target.value;
    },
    unsetOtherLocationArrangement(e) {
      this.$refs.otherLocationArragement.value = "";
    },
    toggleNoFinancial(e) {
      if (e.target.checked) {
        this.savings = "";
        this.khairatKematian = "";
        this.otherFinancialArrangement = "";
        this.otherFinancialArrangementValue = "";
      }
    },

    toCurrency(position, index, e) {
      utils.toCurrency(this, position, index, e.target.value);
    }
  },
  watch: {
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
