<template>
  <v-collapse-wrapper
    name="section"
    :active="true"
    class="mt-12"
    v-on:onStatusChange="this.$parent.toggleCaret"
  >
    <div
      name="section-header"
      v-collapse-toggle
      class="text-center pt-2 pb-2 rounded-full text-gray-50 font-bold uppercase mb-6"
    >
      Assets
      <span class="caret text-xs">╲╱</span>
    </div>

    <section v-collapse-content>
      <!-- SubSection: Moveable Assets -->
      <div
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Moveable Assets
      </div>

      <p class="mb-6 font-bold">Types of movable assets</p>

      <div class="grid grid-cols-3 gap-4">
        <!-- Row -->
        <div class="flex items-center">
          <span class="">EPF</span>
        </div>
        <div class="flex justify-end items-center">
          Current balance
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.epf"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.epf', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center">
          <span class="inline-block align-middle">Tabung Haji</span>
        </div>
        <div class="flex items-center justify-end">
          Current Balance
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.tabungHaji"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.tabungHaji', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center">
          <span class="inline-block align-middle"
            >CASA<br />Current Account Savings Account</span
          >
        </div>
        <div class="flex items-center justify-end">
          Current Balance
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.casa"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.casa', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center col-span-3">
          <span class="inline-block align-middle font-bold text-sm">
            Investments - Please select the invesment types that are applicable
            to you
          </span>
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center ml-12">Unit Trust</span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.unitTrust"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.unitTrust', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center ml-12">
            Collectible Items (eg: Jewelry/Vouchers/Arts/Handbags)
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.collectibleItems"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.collectibleItems', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center ml-12">
            Cryptocurrency (eg: Bitcoin)
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.crypto"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.crypto', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center ml-12">
            Precious Metals
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.preciousMetals"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.preciousMetals', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center ml-12">
            Shares
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.shares"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('moveable.shares', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center ml-12">
            <input
              v-model="moveable.otherMoveable"
              :required="moveable.otherMoveableValue != ''"
              class="mr-2 w-full border-0 border-b disabled:opacity-50"
              type="text"
              placeholder="Others"
            />
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="moveable.otherMoveableValue"
            :required="moveable.otherMoveable != ''"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="
              toCurrency('moveable.otherMoveableValue', undefined, $event)
            "
          />
        </div>

        <!-- Row -->
        <div class="flex items-center col-span-3"></div>
      </div>

      <!-- SubSection: Immovable Assets -->
      <div
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Immovable Assets
      </div>

      <p class="mb-6 font-bold">Types of immovable assets</p>

      <div class="grid grid-cols-3 gap-4">
        <!-- Row -->
        <div class="flex items-center">
          <span class="inline-block align-middle">House</span>
        </div>
        <div class="flex items-center justify-end">
          <InputRange v-model="immovable.houseCount" :min="0" is-small="true" />
        </div>
        <div class="">
          <input
            type="text"
            v-model="immovable.houseValue"
            :required="immovable.houseCount.length > 0"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('immovable.houseValue', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center">
          <span class="inline-block align-middle">Car</span>
        </div>
        <div class="flex items-center justify-end">
          <InputRange v-model="immovable.carCount" :min="0" is-small="true" />
        </div>
        <div class="">
          <input
            type="text"
            v-model="immovable.carValue"
            :required="immovable.carCount.length > 0"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('immovable.carValue', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center">
          <span class="inline-block align-middle">Land</span>
        </div>
        <div class="flex items-center justify-end">
          <InputRange v-model="immovable.landCount" :min="0" is-small="true" />
        </div>
        <div class="">
          <input
            type="text"
            v-model="immovable.landValue"
            :required="immovable.landCount.length > 0"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('immovable.landValue', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center">
          <span class="inline-block align-middle">Takaful Policies</span>
        </div>
        <div class="flex items-center justify-end">
          <InputRange
            v-model="immovable.takafulCount"
            :min="0"
            is-small="true"
          />
        </div>
        <div class="">
          <input
            type="text"
            v-model="immovable.takafulValue"
            :required="immovable.takafulCount.length > 0"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('immovable.takafulValue', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center">
            <input
              v-model="immovable.otherImmoveable"
              :required="immovable.otherImmoveableValue != ''"
              class="mr-2 w-full disabled:opacity-50"
              type="text"
              placeholder="Others"
            />
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="immovable.otherImmoveableValue"
            :required="immovable.otherImmoveable != ''"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="
              toCurrency('immovable.otherImmoveableValue', undefined, $event)
            "
          />
        </div>

        <!-- Row -->
        <div class="flex items-center col-span-3"></div>
      </div>
    </section>
  </v-collapse-wrapper>
</template>

<script>
import Vue from "vue";
import InputRange from "./../components/InputRange";
import utils from "./../utils";

export default Vue.extend({
  props: ["value"],
  data() {
    return {
      // forms
      moveable: {
        epf: "",
        tabungHaji: "",
        casa: "",
        unitTrust: "",
        collectibleItems: "",
        crypto: "",
        preciousMetals: "",
        shares: "",
        otherMoveable: "",
        otherMoveableValue: ""
      },
      immovable: {
        houseCount: 0,
        houseValue: "",
        carCount: 0,
        carValue: "",
        landCount: 0,
        landValue: "",
        takafulCount: 0,
        takafulValue: "",
        otherImmoveable: "",
        otherImmoveableValue: ""
      }
    };
  },
  components: {
    InputRange
  },
  methods: {
    toCurrency(position, index, e) {
      utils.toCurrency(this, position, index, e.target.value);
    }
  },
  watch: {
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
