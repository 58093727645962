<template>
  <div name="top-nav" class="sticky top-0 bg-white mt-6 pt-6 z-50">
    <img alt="logo" :src="logo" />
    <ul
      v-scroll-spy-active
      v-scroll-spy-link
      name="links"
      class="flex flex-row justify-between mt-6 mb-6"
    >
      <li v-scroll-to="'#general-section'" class="active">
        General Information
      </li>
      <li v-scroll-to="'#funeral-section'">Funeral Arrangement</li>
      <li v-scroll-to="'#debts-and-liabilities-section'">
        Debts &amp; Liabilities
      </li>
      <li v-scroll-to="'#assets-section'">Assets</li>
      <li v-scroll-to="'#special-wishes-section'">Special Wishes</li>
      <li v-scroll-to="'#summary-section'">Summary</li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import logo from "./../assets/maybank-islamic.png";

export default Vue.extend({
  data() {
    return {
      logo
    };
  }
});
</script>

<style lang="scss"></style>
