<template>
  <v-collapse-wrapper
    name="section"
    :active="true"
    v-on:onStatusChange="this.$parent.toggleCaret"
  >
    <div
      name="section-header"
      v-collapse-toggle
      class="text-center pt-2 pb-2 rounded-full text-gray-50 font-bold uppercase mb-6"
    >
      General Information
      <span class="caret text-xs">╲╱</span>
    </div>

    <section v-collapse-content>
      <div class="grid grid-cols-3 gap-4">
        <!-- row -->
        <div class="col-span-2">
          <label class="block">
            <span class="required">Customer Name</span>
            <input
              type="text"
              v-model="name"
              required
              class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="As per NRIC"
            />
          </label>
        </div>
        <div class="">
          <label class="block mr-12">
            <span class="required">NRIC</span>
            <input
              type="text"
              v-model="nric"
              required
              maxlength="12"
              class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="As per NRIC"
            />
          </label>
        </div>

        <!-- row -->
        <div class="">
          <div class="required">Gender</div>
          <div class="mt-4 flex">
            <label class="inline-block align-middle mr-6">
              <input
                v-model="gender"
                name="general[gender]"
                readonly
                value="male"
                class="mr-6"
                type="radio"
              />
              Male
            </label>
            <label class="inline-block align-middle">
              <input
                v-model="gender"
                name="general[gender]"
                readonly
                value="female"
                class="mr-6"
                type="radio"
              />
              Female
            </label>
          </div>
        </div>
        <div class="">
          <label class="block mr-12">
            <span class="required">Date of Birth</span>
            <input
              type="text"
              readonly
              v-model="dob"
              required
              class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            />
          </label>
        </div>
        <div class="">
          <label class="block">
            <span class="required">Age</span>
            <input
              type="text"
              name="general[age]"
              v-model="age"
              readonly
              class="mt-4 block w-12 rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            />
          </label>
        </div>
      </div>

      <label class="block mt-6">
        <span class="required">Marital Status</span>
        <div class="mt-4 flex">
          <label class="inline-block align-middle mr-6"
            ><input
              required
              v-model="maritalStatus"
              value="single"
              class="mr-6"
              type="radio"
            />
            Single</label
          >
          <label class="inline-block align-middle mr-6"
            ><input
              required
              v-model="maritalStatus"
              value="married"
              class="mr-6"
              type="radio"
            />
            Married</label
          >
          <label class="inline-block align-middle mr-6"
            ><input
              required
              v-model="maritalStatus"
              value="divorced"
              class="mr-6"
              type="radio"
            />
            Divorced</label
          >
          <label class="inline-block align-middle"
            ><input
              required
              v-model="maritalStatus"
              value="widow"
              class="mr-6"
              type="radio"
            />
            Widow</label
          >
        </div>
      </label>

      <!-- SubSection: Spouse's Information -->
      <div
        v-if="!isSingle() && gender"
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Spouse's Information
      </div>
      <InputSpouse
        v-show="!isSingle() && gender"
        v-model="spouses"
        :applicant-gender="gender"
        :marital-status="maritalStatus"
        :value="spouses.length"
      />

      <!-- SubSection: Dependant's Information -->
      <div
        v-if="!isSingle()"
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Dependant's Information
      </div>
      <InputChildren
        v-if="!isSingle()"
        v-model="children"
        :spouses="spouses"
        :applicant-gender="gender"
        :applicant-name="name"
      />

      <!-- SubSection: Relatives's Information -->
      <div
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Relative's Information
      </div>
      <label class="block mt-6">
        <span class="mr-12">Number of Relatives</span>
        <InputRange v-model="noOfRelatives" min="0" />
      </label>

      <div class="grid grid-cols-3 gap-4">
        <template v-for="(r, i) in relatives">
          <!-- row -->
          <hr
            class="mt-6 mb-6 bg-top-c border-yellow-400 col-span-3"
            v-bind:key="`A${i}`"
          />

          <!-- row -->
          <div v-bind:key="`${i}A`" class="col-span-2">
            <label class="block">
              <span class="required">Relative Name</span>
              <input
                type="text"
                required
                v-model="r.name"
                class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
                placeholder="As per NRIC"
              />
            </label>
          </div>
          <div v-bind:key="`${i}B`" class="">
            <label class="block mr-12">
              <span class="required">Relationship</span>
              <select
                v-model="r.relationship"
                required
                class="mt-4 block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
              >
                <option selected value="">Please Select</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Nephew">Nephew</option>
                <option value="Niece">Niece</option>
                <option value="Grandchildren">Grandchildren</option>
              </select>
            </label>
          </div>
        </template>
      </div>
    </section>
  </v-collapse-wrapper>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InputSpouse from "./../components/InputSpouse";
import InputChildren from "./../components/InputChildren";
import InputRange from "./../components/InputRange";

dayjs.extend(customParseFormat);

const relative = {
  name: "",
  relationship: ""
};

export default Vue.extend({
  props: ["value"],
  data() {
    return {
      noOfRelatives: 0,

      // form data
      name: "",
      nric: "",
      gender: "",
      dob: "",
      age: "",
      maritalStatus: "",
      spouses: [{}],
      children: [],
      relatives: []
    };
  },
  components: {
    InputSpouse,
    InputChildren,
    InputRange
  },
  methods: {
    isSingle() {
      return this.maritalStatus === "single";
    }
  },
  watch: {
    nric() {
      if (this.nric.length === 12) {
        const dobPart = this.nric.slice(0, 6);
        const genderPart = this.nric[this.nric.length - 1];
        let dob = dayjs(dobPart, "YYMMDD");

        if (dayjs().diff(dob, "ms") < 0) {
          dob = dob.subtract(100, "year");
        }

        this.gender = genderPart % 2 ? "male" : "female";
        this.dob = dob.format("DD/MM/YYYY");
        this.age = dayjs().diff(dob, "years");
      }
    },
    noOfRelatives: {
      handler: function(val) {
        if (this.relatives.length < this.noOfRelatives) {
          this.relatives.push({
            ...relative
          });
        } else {
          this.relatives.pop();
        }
      },
      deep: true
    },
    maritalStatus(val) {
      // when gender is changed to single, need to reset number of spouses and children
      // because need to reset cash to spouse and cash to children
      if (val === "single") {
        this.spouses = [{}];
        this.children = [];
      }
    },
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
