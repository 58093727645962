<template>
  <v-collapse-wrapper
    name="section"
    :active="true"
    class="mt-12"
    v-on:onStatusChange="this.$parent.toggleCaret"
  >
    <div
      name="section-header"
      v-collapse-toggle
      class="text-center pt-2 pb-2 rounded-full text-gray-50 font-bold uppercase mb-6"
    >
      Debts &amp; Liabilities
      <span class="caret text-xs">╲╱</span>
    </div>

    <section v-collapse-content>
      <!-- SubSection: Religious Obligations -->
      <div
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Religious Obligations
      </div>

      <p class="mb-6 font-bold">Types of obligations</p>

      <div class="grid grid-cols-3 gap-4">
        <!-- Row -->
        <div class="col-span-2">
          Zakat Fitrah
        </div>
        <div class="">
          <input
            type="text"
            v-model="religious.zakatFitrah"
            @input="toCurrency('religious.zakatFitrah', undefined, $event)"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          Zakat Harta
        </div>
        <div class="">
          <input
            type="text"
            v-model="religious.zakatHarta"
            @input="toCurrency('religious.zakatHarta', undefined, $event)"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          Badal Haji
        </div>
        <div class="">
          <input
            type="text"
            v-model="religious.badalHaji"
            @input="toCurrency('religious.badalHaji', undefined, $event)"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          Puasa
        </div>
        <div class="">
          <input
            type="text"
            v-model="religious.puasa"
            @input="toCurrency('religious.puasa', undefined, $event)"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>

        <!-- Row -->
        <template v-for="(charity, i) in religious.ongoingCharity">
          <hr class="col-span-3" v-bind:key="`D${i}`" v-if="i > 0" />
          <div class="col-span-2" v-bind:key="`A${i}`">
            Ongoing Charity:
            <input
              type="text"
              :required="isCharityRequired(i)"
              v-model="charity.name"
              class="p-1 rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Charity name"
            />
            <p
              v-if="i === 0"
              class="text-xs text-red-400 cursor-pointer"
              @click="addOngoingCharity"
            >
              + add charity
            </p>
            <p
              v-if="i > 0"
              class="text-xs text-red-400 cursor-pointer"
              @click="removeOngoingCharity"
            >
              x remove charity
            </p>
          </div>
          <div class="" v-bind:key="`B${i}`">
            <input
              type="text"
              :required="isCharityRequired(i)"
              v-model="charity.value"
              @input="toCurrency('religious.ongoingCharity', i, $event)"
              class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="col-span-2" v-bind:key="`C${i}`">
            <div class="grid grid-cols-3 gap-4 ml-12">
              <!-- Inner Row -->
              <div>
                <label class="">
                  <input
                    v-model="charity.periodicity"
                    :required="isCharityRequired(i) && !charity.periodicity"
                    @input="unsetCharityPeriodicity(i, $event)"
                    :name="`charity.periodicity${i}`"
                    value="daily"
                    class="mr-2"
                    type="radio"
                  />
                  Daily
                </label>
              </div>
              <div>
                <label class="">
                  <input
                    v-model="charity.periodicity"
                    :required="isCharityRequired(i) && !charity.periodicity"
                    @input="unsetCharityPeriodicity(i, $event)"
                    :name="`charity.periodicity${i}`"
                    value="weekly"
                    class="mr-2"
                    type="radio"
                  />
                  Weekly
                </label>
              </div>
              <div>
                <label class="">
                  <input
                    v-model="charity.periodicity"
                    :required="isCharityRequired(i) && !charity.periodicity"
                    @input="unsetCharityPeriodicity(i, $event)"
                    :name="`charity.periodicity${i}`"
                    value="monthly"
                    class="mr-2"
                    type="radio"
                  />
                  Monthly
                </label>
              </div>

              <!-- Inner Row -->
              <div>
                <label class="">
                  <input
                    v-model="charity.periodicity"
                    :required="isCharityRequired(i) && !charity.periodicity"
                    @input="unsetCharityPeriodicity(i, $event)"
                    :name="`charity.periodicity${i}`"
                    value="quarterly"
                    class="mr-2"
                    type="radio"
                  />
                  Quarterly
                </label>
              </div>
              <div>
                <label class="">
                  <input
                    v-model="charity.periodicity"
                    :required="isCharityRequired(i) && !charity.periodicity"
                    @input="unsetCharityPeriodicity(i, $event)"
                    :name="`charity.periodicity${i}`"
                    value="annually"
                    class="mr-2"
                    type="radio"
                  />
                  Annually
                </label>
              </div>
              <div>
                <span class="inline-flex items-center">
                  <input
                    class="mr-2 border-0 border-b"
                    :required="isCharityRequired(i) && !charity.periodicity"
                    @input="setCharityPeriodicity(i, $event)"
                    type="text"
                    placeholder="Others"
                    :ref="`otherPeriodicity${i}`"
                  />
                </span>
              </div>
            </div>
          </div>
        </template>

        <!-- Row -->
        <div class="flex items-center col-span-2">
          <span class="inline-flex items-center">
            <input
              v-model="religious.otherObligations"
              class="mr-2 border-0 border-b"
              :required="religious.otherObligationsValue !== ''"
              type="text"
              placeholder="Others"
            />
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="religious.otherObligationsValue"
            :required="religious.otherObligations !== ''"
            @input="
              toCurrency('religious.otherObligationsValue', undefined, $event)
            "
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>
      </div>

      <hr class="mt-6 bg-top-c border-yellow-400" />

      <p class="mt-6 required">
        Is the amount of debt and liabilities accurate?
      </p>
      <label class="block">
        <input
          v-model="religious.accurate"
          value="yes"
          class="mr-2"
          required
          name="religious.accurate"
          type="radio"
        />
        Yes, customer has good visibilities of the debts and liabilities
      </label>
      <label class="block">
        <input
          v-model="religious.accurate"
          value="no"
          class="mr-2"
          required
          name="religious.accurate"
          type="radio"
        />
        No, customer has limited visibility of the debts and liabilities
      </label>

      <!-- SubSection: Other Debts & Liabilities -->
      <div
        name="sub-section-header"
        class="mt-6 text-center rounded-full text-black font-bold mb-6"
      >
        Other Debts &amp; Liabilities
      </div>

      <p class="mt-6 font-bold">Types of debts or liabilities?</p>

      <span class="block mt-6 font-italic">
        Bank
      </span>

      <div class="grid grid-cols-3 gap-4 ml-6 mt-6">
        <!-- Mortgages -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          Mortgage
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.bank.mortgages.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('bank', 'mortgages', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(mortage, i) in others.bank.mortgages">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A${i}`" />
          <div class="flex items-center" v-bind:key="`B${i}`">
            <select
              :required="
                isBankLiabilityRequired('mortgages', 'mrtt', i) && !mortage.bank
              "
              v-model="mortage.bank"
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C${i}`"
          >
            Outstanding Amount
            <input
              :required="
                isBankLiabilityRequired('mortgages', 'mrtt', i) &&
                  !mortage.value
              "
              @input="toCurrency('others.bank.mortgages', i, $event)"
              type="text"
              v-model="mortage.value"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D${i}`">
            Covered with MRTT?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                :required="
                  isBankLiabilityRequired('mortgages', 'mrtt', i) &&
                    !mortage.mrtt
                "
                v-model="mortage.mrtt"
                :name="`mortage.mrtt${i}`"
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                :required="
                  isBankLiabilityRequired('mortgages', 'mrtt', i) &&
                    !mortage.mrtt
                "
                v-model="mortage.mrtt"
                :name="`mortage.mrtt${i}`"
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- Hire Purchase -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          Hire Purchase
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.bank.hirePurchases.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('bank', 'hirePurchases', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(hp, i) in others.bank.hirePurchases">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A1${i}`" />
          <div class="flex items-center" v-bind:key="`B1${i}`">
            <select
              v-model="hp.bank"
              :required="
                isBankLiabilityRequired('hirePurchases', 'tacp', i) && !hp.bank
              "
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C1${i}`"
          >
            Outstanding Amount
            <input
              type="text"
              :required="
                isBankLiabilityRequired('hirePurchases', 'tacp', i) && !hp.value
              "
              @input="toCurrency('others.bank.hirePurchases', i, $event)"
              v-model="hp.value"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D1${i}`">
            Covered with TACP?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E1${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                :required="
                  isBankLiabilityRequired('hirePurchases', 'tacp', i) &&
                    !hp.tacp
                "
                :name="`hp.tacp${i}`"
                v-model="hp.tacp"
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                :required="
                  isBankLiabilityRequired('hirePurchases', 'tacp', i) &&
                    !hp.tacp
                "
                :name="`hp.tacp${i}`"
                v-model="hp.tacp"
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- Credit Cards -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          Credit Cards
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.bank.creditCards.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('bank', 'creditCards', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(cc, i) in others.bank.creditCards">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A2${i}`" />
          <div class="flex items-center" v-bind:key="`B2${i}`">
            <select
              v-model="cc.bank"
              :required="
                isBankLiabilityRequired('creditCards', 'ccpp', i) && !cc.bank
              "
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C2${i}`"
          >
            Outstanding Amount
            <input
              type="text"
              :required="
                isBankLiabilityRequired('creditCards', 'ccpp', i) && !cc.value
              "
              @input="toCurrency('others.bank.creditCards', i, $event)"
              v-model="cc.value"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D2${i}`">
            Covered with Credit Card Plus Plan (CCPP)?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E2${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                :required="
                  isBankLiabilityRequired('creditCards', 'ccpp', i) && !cc.ccpp
                "
                :name="`cc.ccpp${i}`"
                v-model="cc.ccpp"
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                :required="
                  isBankLiabilityRequired('creditCards', 'ccpp', i) && !cc.ccpp
                "
                :name="`cc.ccpp${i}`"
                v-model="cc.ccpp"
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- Others -->
        <!-- Row -->
        <div class="flex items-center">
          <input
            v-model="others.bank.otherDebt"
            :required="others.bank.otherDebtValue != ''"
            class="mr-2 border-0 border-b"
            type="text"
            placeholder="Others"
          />
        </div>
        <div class="col-span-2 flex items-center justify-end">
          Outstanding Amount
          <input
            type="text"
            v-model="others.bank.otherDebtValue"
            @input="toCurrency('others.bank.otherDebtValue', undefined, $event)"
            :required="others.bank.otherDebt != ''"
            class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>
      </div>

      <hr class="col-span-3 bg-top-c border-yellow-400 mt-6" />

      <span class="block mt-6">
        Retail Financing
      </span>

      <div class="grid grid-cols-3 gap-4 ml-6 mt-6">
        <!-- ASB LOAN -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          ASB Loan
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.retail.asb.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('retail', 'asb', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(loan, i) in others.retail.asb">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A${i}`" />
          <div class="flex items-center" v-bind:key="`B${i}`">
            <select
              v-model="loan.bank"
              :required="isRetailLiabilityRequired('asb', i) && !loan.bank"
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C${i}`"
          >
            Outstanding Amount
            <input
              type="text"
              :required="isRetailLiabilityRequired('asb', i) && !loan.value"
              @input="toCurrency('others.retail.asb', i, $event)"
              v-model="loan.value"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D${i}`">
            Covered by PRTT?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                :required="isRetailLiabilityRequired('asb', i) && !loan.prtt"
                :name="`loanAsb.prtt${i}`"
                v-model="loan.prtt"
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                :required="isRetailLiabilityRequired('asb', i) && !loan.prtt"
                :name="`loanAsb.prtt${i}`"
                v-model="loan.prtt"
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- PERSONAL LOAN -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          Personal Loan
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.retail.personal.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('retail', 'personal', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(loan, i) in others.retail.personal">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A1${i}`" />
          <div class="flex items-center" v-bind:key="`B1${i}`">
            <select
              v-model="loan.bank"
              :required="isRetailLiabilityRequired('personal', i) && !loan.bank"
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C1${i}`"
          >
            Outstanding Amount
            <input
              type="text"
              :required="
                isRetailLiabilityRequired('personal', i) && !loan.value
              "
              v-model="loan.value"
              @input="toCurrency('others.retail.personal', i, $event)"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D1${i}`">
            Covered by PRTT?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E1${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                :required="
                  isRetailLiabilityRequired('personal', i) && !loan.prtt
                "
                :name="`loanPersonal.prtt${i}`"
                v-model="loan.prtt"
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                :required="
                  isRetailLiabilityRequired('personal', i) && !loan.prtt
                "
                :name="`loanPersonal.prtt${i}`"
                v-model="loan.prtt"
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- OVERDRAFT -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          Overdraft
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.retail.overdraft.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('retail', 'overdraft', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(loan, i) in others.retail.overdraft">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A2${i}`" />
          <div class="flex items-center" v-bind:key="`B2${i}`">
            <select
              v-model="loan.bank"
              :required="
                isRetailLiabilityRequired('overdraft', i) && !loan.bank
              "
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C2${i}`"
          >
            Outstanding Amount
            <input
              type="text"
              :required="
                isRetailLiabilityRequired('overdraft', i) && !loan.value
              "
              v-model="loan.value"
              @input="toCurrency('others.retail.overdraft', i, $event)"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D2${i}`">
            Covered by PRTT?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E2${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                v-model="loan.prtt"
                :name="`loanOd.prtt${i}`"
                :required="
                  isRetailLiabilityRequired('overdraft', i) && !loan.prtt
                "
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                v-model="loan.prtt"
                :name="`loanOd.prtt${i}`"
                :required="
                  isRetailLiabilityRequired('overdraft', i) && !loan.prtt
                "
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- Education LOAN -->
        <!-- Row -->
        <div class="col-span-2 flex items-center">
          Education Loan
        </div>
        <div class="flex items-center justify-end">
          Quantity
          <div class="ml-6 flex items-center justify-end">
            <InputRange
              :value="others.retail.education.length"
              :min="1"
              is-small="true"
              v-on:input="onCountChange('retail', 'education', $event)"
            />
          </div>
        </div>

        <!-- Row -->
        <template v-for="(loan, i) in others.retail.education">
          <hr v-if="i > 0" class="col-span-3" v-bind:key="`A3${i}`" />
          <div class="flex items-center" v-bind:key="`B3${i}`">
            <select
              v-model="loan.bank"
              :required="
                isRetailLiabilityRequired('education', i) && !loan.bank
              "
              class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option value="">Banking Institutions</option>
              <option v-for="(b, i) in banks" v-bind:key="i">{{ b }}</option>
            </select>
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`C3${i}`"
          >
            Outstanding Amount
            <input
              type="text"
              :required="
                isRetailLiabilityRequired('education', i) && !loan.value
              "
              v-model="loan.value"
              @input="toCurrency('others.retail.education', i, $event)"
              class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Please state the amount (RM)"
            />
          </div>

          <!-- Row -->
          <div class="flex items-center" v-bind:key="`D3${i}`">
            Covered by PRTT?
          </div>
          <div
            class="col-span-2 flex items-center justify-end"
            v-bind:key="`E3${i}`"
          >
            <label class="inline-block align-middle mr-6"
              ><input
                :required="
                  isRetailLiabilityRequired('education', i) && !loan.prtt
                "
                :name="`loanEdu.prtt${i}`"
                v-model="loan.prtt"
                value="yes"
                class="mr-6"
                type="radio"
              />
              Yes</label
            >
            <label class="inline-block align-middle"
              ><input
                :required="
                  isRetailLiabilityRequired('education', i) && !loan.prtt
                "
                :name="`loanEdu.prtt${i}`"
                v-model="loan.prtt"
                value="no"
                class="mr-6"
                type="radio"
              />
              No</label
            >
          </div>
        </template>

        <hr class="col-span-3" />

        <!-- Others -->
        <!-- Row -->
        <div class="flex items-center">
          <input
            v-model="others.retail.otherDebt"
            class="mr-2 border-0 border-b"
            :required="others.retail.otherDebtValue != ''"
            type="text"
            placeholder="Others"
          />
        </div>
        <div class="col-span-2 flex items-center justify-end">
          Outstanding Amount
          <input
            type="text"
            v-model="others.retail.otherDebtValue"
            @input="
              toCurrency('others.retail.otherDebtValue', undefined, $event)
            "
            :required="others.retail.otherDebt != ''"
            class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>
      </div>

      <hr class="col-span-3 bg-top-c border-yellow-400 mt-6" />

      <div class="grid grid-cols-3 gap-4 ml-6 mt-6">
        <!-- Row -->
        <div class="">
          <span class="flex items-center">
            Family Members
          </span>
        </div>
        <div class="col-span-2">
          <div class="flex items-center justify-end">
            Number of family members
            <div class="ml-6 flex items-center justify-end">
              <InputRange
                :value="others.family.members.length"
                :min="1"
                is-small="true"
                v-on:input="onCountChange('family', 'members', $event)"
              />
            </div>
          </div>
        </div>

        <!-- Row -->
        <div
          class="col-span-3"
          v-for="(member, i) in others.family.members"
          v-bind:key="`X${i}`"
        >
          <hr v-if="i > 0" class="col-span-3 mt-6 mb-6" />
          <div class="grid grid-cols-5 gap-2 ml-12">
            <!-- Inner Row -->
            <div>
              <label class="">
                <input
                  v-model="member.relationship"
                  @input="unsetFamilyRelationships(i, $event)"
                  :name="`member.relationship${i}`"
                  value="parents"
                  class="mr-2"
                  type="radio"
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                />
                Parents
              </label>
            </div>
            <div>
              <label class="">
                <input
                  v-model="member.relationship"
                  @input="unsetFamilyRelationships(i, $event)"
                  :name="`member.relationship${i}`"
                  value="siblings"
                  class="mr-2"
                  type="radio"
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                />
                Siblings
              </label>
            </div>
            <div class="col-span-3">
              <label>
                <input
                  v-model="member.relationship"
                  @input="unsetFamilyRelationships(i, $event)"
                  :name="`member.relationship${i}`"
                  value="in-laws"
                  class="mr-2"
                  type="radio"
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                />
                In-Laws
              </label>
            </div>

            <!-- Inner Row -->
            <div>
              <label class="">
                <input
                  v-model="member.relationship"
                  @input="unsetFamilyRelationships(i, $event)"
                  :name="`member.relationship${i}`"
                  value="cousins"
                  class="mr-2"
                  type="radio"
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                />
                Cousins
              </label>
            </div>
            <div>
              <label class="">
                <input
                  v-model="member.relationship"
                  @input="unsetFamilyRelationships(i, $event)"
                  :name="`member.relationship${i}`"
                  value="uncles/aunties"
                  class="mr-2"
                  type="radio"
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                />
                Uncles/Aunties
              </label>
            </div>
            <div>
              <label class="">
                <input
                  v-model="member.relationship"
                  @input="unsetFamilyRelationships(i, $event)"
                  :name="`member.relationship${i}`"
                  value="nephews/nieces"
                  class="mr-2"
                  type="radio"
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                />
                Nephews/Nieces
              </label>
            </div>
            <div>
              <span class="inline-flex items-center">
                <input
                  :required="isFamilyMemberRequired(i) && !member.relationship"
                  @input="setFamilyRelationships(i, $event)"
                  class="mr-2 border-0 border-b"
                  type="text"
                  placeholder="Others"
                  :ref="`familyMembers${i}`"
                />
              </span>
            </div>

            <!-- Inner Row -->
            <div class="col-span-3 mt-4">
              <input
                type="text"
                v-model="member.name"
                class="block w-full focus:border-gray-900 focus:bg-white focus:ring-0 border-0 border-b"
                placeholder="Name as per NRIC"
                :required="isFamilyMemberRequired(i) && !member.name"
              />
            </div>
            <div class="col-span-2 flex items-center justify-end mt-4">
              <input
                type="text"
                v-model="member.value"
                class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
                placeholder="Please state the amount (RM)"
                :required="isFamilyMemberRequired(i) && !member.value"
                @input="toCurrency('others.family.members', i, $event)"
              />
            </div>
          </div>
        </div>

        <hr class="col-span-3" />

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center">
            <input
              v-model="others.family.otherFamily"
              :required="others.family.otherFamilyValue != ''"
              class="mr-2 border-0 border-b"
              type="text"
              placeholder="Others"
            />
          </span>
        </div>
        <div class="flex items-center justify-end">
          <input
            type="text"
            v-model="others.family.otherFamilyValue"
            @input="
              toCurrency('others.family.otherFamilyValue', undefined, $event)
            "
            :required="others.family.otherFamily != ''"
            class="ml-6 block rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            placeholder="Please state the amount (RM)"
          />
        </div>
      </div>

      <hr class="col-span-3 bg-top-c border-yellow-400 mt-6" />

      <p class="mt-6 required">
        Is the amount of debt and liabilities accurate?
      </p>
      <label class="block">
        <input
          v-model="others.retail.accurate"
          name="others.retail.accurate"
          required
          value="yes"
          class="mr-2"
          type="radio"
        />
        Yes, customer has good visibilities of the debts and liabilities
      </label>
      <label class="block">
        <input
          v-model="others.retail.accurate"
          name="others.retail.accurate"
          required
          value="no"
          class="mr-2"
          type="radio"
        />
        No, customer has limited visibility of the debts and liabilities
      </label>
    </section>
  </v-collapse-wrapper>
</template>

<script>
import Vue from "vue";
import InputRange from "./../components/InputRange";
import utils from "./../utils";

const banks = [
  "Maybank / Maybank Islamic",
  "Affin Bank Berhad / Affin Islamic Bank",
  "Al-Rajhi Banking & Investment Corp (M) Berhad",
  "Alliance Bank Malaysia Berhad",
  "AmBank Berhad",
  "Bank Islam Malaysia",
  "Bank Kerjasama Rakyat Malaysia Berhad",
  "Bank Muamalat",
  "Bank of Amerca",
  "Bank of China (Malaysia) Berhad",
  "Bank Pertanian Malaysia (Agrobank)",
  "Bank Simpanan Nasional Berhad",
  "BNP Paribas Malaysia",
  "China Const Bk (M) BHD",
  "CIMB Bank Berhad",
  "Citibank Berhad",
  "Deutsche Bank (Msia) Berhad",
  "Hong Leong Bank",
  "HSBC Bank Malaysia Berhad",
  "Industrial & Commercial Bank of China",
  "J.P Morgan Chase Bank Berhad",
  "Kuwait Finance House (Malaysia) BHD",
  "MBSB Bank",
  "Mizuho Bank (Malaysia) Berhad",
  "MUFG Bank (Malaysia) BHD",
  "OCBC Bank (Malaysia) BHD",
  "RHB Bank",
  "Standard Chartered Bank",
  "Sumitomo Mitsui Banking Corporation Malaysia BHD",
  "United Overseas Bank Berhad"
];

const ongoingCharity = {
  name: "",
  value: "",
  periodicity: ""
};

const mortgage = {
  bank: "",
  value: "",
  mrtt: ""
};

const hp = {
  bank: "",
  value: "",
  tacp: ""
};

const cc = {
  bank: "",
  value: "",
  ccpp: "" // CreditCartPlusPlan
};

const retail = {
  bank: "",
  value: "",
  prtt: "" // CreditCartPlusPlan
};

const family = {
  relationship: "",
  name: "",
  value: ""
};

export default Vue.extend({
  props: ["value"],
  data() {
    return {
      banks,

      religious: {
        zakatFitrah: "",
        zakatHarta: "",
        badalHaji: "",
        puasa: "",
        ongoingCharity: [{ ...ongoingCharity }],
        otherObligations: "",
        otherObligationsValue: "",
        accurate: ""
      },
      others: {
        bank: {
          mortgages: [
            {
              ...mortgage
            }
          ],
          hirePurchases: [{ ...hp }],
          creditCards: [{ ...cc }],
          otherDebt: "",
          otherDebtValue: ""
        },
        retail: {
          asb: [{ ...retail }],
          personal: [{ ...retail }],
          overdraft: [{ ...retail }],
          education: [{ ...retail }],
          otherDebt: "",
          otherDebtValue: ""
        },
        family: {
          members: [
            {
              ...family
            }
          ],
          otherFamily: "",
          otherFamilyValue: ""
        },
        accurate: ""
      }
    };
  },
  components: {
    InputRange
  },
  methods: {
    addOngoingCharity() {
      this.religious.ongoingCharity.push({ ...ongoingCharity });
    },

    removeOngoingCharity() {
      this.religious.ongoingCharity.pop();
    },

    onCountChange(type, index, currentCount) {
      const diff = currentCount - this.others[type][index].length;
      if (diff === 0) return;
      if (diff > 0) {
        this.others[type][index].push({ ...mortgage });
      } else {
        this.others[type][index].pop();
      }
    },

    setCharityPeriodicity(index, e) {
      this.religious.ongoingCharity[index].periodicity = e.target.value;
    },
    unsetCharityPeriodicity(i, e) {
      this.$refs[`otherPeriodicity${i}`][0].value = "";
    },

    setFamilyRelationships(index, e) {
      this.others.family.members[index].relationship = e.target.value;
    },
    unsetFamilyRelationships(i, e) {
      this.$refs[`familyMembers${i}`][0].value = "";
    },

    toCurrency(position, index, e) {
      utils.toCurrency(this, position, index, e.target.value);
    },

    isCharityRequired(index) {
      const charity = this.religious.ongoingCharity[index];
      return (
        charity.name !== "" ||
        charity.periodicity !== "" ||
        charity.value !== ""
      );
    },

    isBankLiabilityRequired(type, column, index) {
      const bankLiability = this.others.bank[type][index];
      return (
        bankLiability.bank !== "" ||
        bankLiability.value !== "" ||
        bankLiability[column] !== ""
      );
    },

    isRetailLiabilityRequired(type, index) {
      const liability = this.others.retail[type][index];
      return (
        liability.bank !== "" || liability.value !== "" || liability.prtt !== ""
      );
    },

    isFamilyMemberRequired(index) {
      const family = this.others.family.members[index];
      return (
        family.name !== "" || family.relationship !== "" || family.value !== ""
      );
    }
  },
  watch: {
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
