<template>
  <span class="inline-flex">
    <template v-if="!this.isSmall">
      <button
        class="bg-yellow-500 w-12 h-12 text-black rounded-l-lg disabled:opacity-50"
        v-on:click.prevent="decrement"
        :disabled="disabled"
      >
        -
      </button>
      <input
        type="number"
        class="w-12 relative px-1 py-3 border-0 bg-gray-100 text-center disabled:opacity-50"
        :value="this.content"
        :disabled="disabled"
      />
      <button
        class="bg-yellow-500 w-12 h-12 text-black rounded-r-lg"
        v-on:click.prevent="increment"
        :disabled="disabled"
      >
        +
      </button>
    </template>

    <template v-else>
      <button
        class="bg-yellow-500 w-8 h-8 text-black rounded-l-lg disabled:opacity-50"
        v-on:click.prevent="decrement"
        :disabled="disabled"
      >
        -
      </button>
      <input
        type="number"
        class="w-8 h-8 relative px-0 border-0 bg-gray-100 text-center disabled:opacity-50"
        :value="this.content"
        :disabled="disabled"
      />
      <button
        class="bg-yellow-500 w-8 h-8 text-black rounded-r-lg disabled:opacity-50"
        v-on:click.prevent="increment"
        :disabled="disabled"
      >
        +
      </button>
    </template>
  </span>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["value", "min", "max", "isSmall", "disabled"],
  data() {
    return {
      content: this.value
    };
  },

  methods: {
    increment() {
      if (this.max === this.content) {
        return;
      }
      this.content++;
      this.$emit("input", this.content);
    },
    decrement() {
      if (this.min < this.content) {
        this.content--;
      }
      this.$emit("input", this.content);
    }
  },
  watch: {
    value: function(val) {
      this.content = val;
    }
  }
});
</script>

<style lang="scss" scoped></style>
