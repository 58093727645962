<template>
  <v-collapse-wrapper
    name="section"
    :active="true"
    class="mt-12"
    v-on:onStatusChange="this.$parent.toggleCaret"
  >
    <div
      name="section-header"
      v-collapse-toggle
      class="text-center pt-2 pb-2 rounded-full text-gray-50 font-bold uppercase mb-6"
    >
      Summary
      <span class="caret text-xs">╲╱</span>
    </div>

    <section v-collapse-content>
      <div
        class="grid grid-cols-4 gap-4 rounded-lg p-7"
        style="background-color: #363636"
      >
        <!-- Row -->
        <div class="">
          <p>Moveable Assets</p>

          <h5 class="text-white tracking-wide">EPF</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">{{ epfValue$ }}</h6>

          <h5 class="text-white tracking-wide">Tabung Haji</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ tabungHajiValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">CASA</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ casaValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">Investment</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ invesmentValue$ }}
          </h6>
        </div>
        <div class="">
          <p>Immoveable Assets</p>

          <h5 class="text-white tracking-wide">House(s)</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ houseValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">Car</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">{{ carValue$ }}</h6>

          <h5 class="text-white tracking-wide">Land</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ landValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">Takaful Policies</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ takafulValue$ }}
          </h6>
        </div>
        <div
          style="border-left: 3px solid #707070; padding-left: 83px"
          class="col-span-2"
        >
          <p>Summary Wealth Projection</p>

          <h5 class="text-white tracking-wide">Total assets value</h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ assetsValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">
            Total amount for funeral arrangement
          </h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ funeralValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">
            Total amount of debts and liabilities
          </h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ debtsAndLiabilitiesValue$ }}
          </h6>

          <h5 class="text-white tracking-wide">
            Total amount of special wishes
          </h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ specialWishesValue$ }}
          </h6>

          <h5 class="text-white uppercase font-bold tracking-wide">
            Estate Value
          </h5>
          <h6 style="color: #FFBF00" class="font-bold mb-3">
            {{ estateValue$ }}
          </h6>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-4 rounded-lg py-6 px-12">
        <!-- Row -->
        <div class="flex col-span-12">
          <p class="">
            Please provide email to receive a copy of the portfolio
          </p>
        </div>

        <!-- Row -->
        <div class="flex items-center col-span-2">
          <p class="required">Agent Name</p>
        </div>
        <div class="flex items-center col-span-10">
          <input
            type="text"
            v-model="agentName"
            required
            placeholder="Name"
            class="block w-6/12 rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center col-span-2">
          <p class="required">Agent Email</p>
        </div>
        <div class="flex items-center col-span-10">
          <input
            type="email"
            v-model="agentEmail"
            required
            placeholder="Email"
            class="block w-6/12 rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
          />
        </div>

        <!-- Row -->
        <div class="flex items-center col-span-2">
          <p class="">Customer Email</p>
        </div>
        <div class="flex items-center col-span-10">
          <input
            type="email"
            v-model="customerEmail"
            placeholder="Email"
            class="block w-6/12 rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
          />
        </div>
      </div>
    </section>
  </v-collapse-wrapper>
</template>

<script>
import Vue from "vue";
import utils from "./../utils";

export default Vue.extend({
  props: ["value", "funeral", "debtsAndLiabilities", "assets", "specialWishes"],
  data() {
    return {
      assetsValue: 0,
      funeralValue: 0,
      debtsAndLiabilitiesValue: 0,
      specialWishesValue: 0,
      agentName: undefined,
      agentEmail: undefined,
      customerEmail: undefined
    };
  },
  watch: {
    assets: {
      handler: function(newVal, oldVal) {
        this.assetsValue = [
          utils.currencyToNumber(this.assets.moveable.epf),
          utils.currencyToNumber(this.assets.moveable.tabungHaji),
          utils.currencyToNumber(this.assets.moveable.casa),
          utils.currencyToNumber(this.assets.moveable.unitTrust),
          utils.currencyToNumber(this.assets.moveable.collectibleItems),
          utils.currencyToNumber(this.assets.moveable.crypto),
          utils.currencyToNumber(this.assets.moveable.preciousMetals),
          utils.currencyToNumber(this.assets.moveable.shares),
          utils.currencyToNumber(this.assets.moveable.otherMoveableValue),

          utils.currencyToNumber(this.assets.immovable.houseValue),
          utils.currencyToNumber(this.assets.immovable.carValue),
          utils.currencyToNumber(this.assets.immovable.landValue),
          utils.currencyToNumber(this.assets.immovable.takafulValue),
          utils.currencyToNumber(this.assets.immovable.otherImmoveableValue)
        ].reduce((acc, current, i) => acc + current, 0);
      },
      deep: true
    },
    funeral: {
      handler: function(newVal, oldVal) {
        this.funeralValue = [
          utils.currencyToNumber(this.funeral.savings),
          utils.currencyToNumber(this.funeral.khairatKematian),
          utils.currencyToNumber(this.funeral.otherFinancialArrangementValue)
        ].reduce((acc, current, i) => acc + current, 0);
      },
      deep: true
    },
    debtsAndLiabilities: {
      handler: function(newVal, oldVal) {
        this.debtsAndLiabilitiesValue = [
          utils.currencyToNumber(
            this.debtsAndLiabilities.religious.zakatFitrah
          ),
          utils.currencyToNumber(this.debtsAndLiabilities.religious.zakatHarta),
          utils.currencyToNumber(this.debtsAndLiabilities.religious.badalHaji),
          utils.currencyToNumber(this.debtsAndLiabilities.religious.puasa),
          +this.debtsAndLiabilities.religious.ongoingCharity.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          utils.currencyToNumber(
            this.debtsAndLiabilities.religious.otherObligationsValue
          ),

          +this.debtsAndLiabilities.others.bank.mortgages.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          +this.debtsAndLiabilities.others.bank.hirePurchases.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          +this.debtsAndLiabilities.others.bank.creditCards.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          utils.currencyToNumber(
            this.debtsAndLiabilities.others.bank.otherDebtValue
          ),

          +this.debtsAndLiabilities.others.retail.asb.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          +this.debtsAndLiabilities.others.retail.personal.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          +this.debtsAndLiabilities.others.retail.overdraft.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          +this.debtsAndLiabilities.others.retail.education.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          utils.currencyToNumber(
            this.debtsAndLiabilities.others.retail.otherDebtValue
          ),

          +this.debtsAndLiabilities.others.family.members.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          utils.currencyToNumber(
            this.debtsAndLiabilities.others.family.otherFamilyValue
          )
        ].reduce((acc, current, i) => acc + current, 0);
      },
      deep: true
    },
    specialWishes: {
      handler: function(newVal, oldVal) {
        this.specialWishesValue = [
          +this.specialWishes.cashToSpouse.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          +this.specialWishes.cashToChildren.reduce(
            (acc, cur) => acc + utils.currencyToNumber(cur.value),
            0
          ),
          utils.currencyToNumber(this.specialWishes.debtsPaidImmediately),
          utils.currencyToNumber(this.specialWishes.sedeqah),
          utils.currencyToNumber(this.specialWishes.donation),
          utils.currencyToNumber(this.specialWishes.waqaf),
          utils.currencyToNumber(this.specialWishes.otherSpecialWishesValue)
        ].reduce((acc, current, i) => acc + current, 0);
      },
      deep: true
    },
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  computed: {
    funeralValue$() {
      return utils.numberToCurrency(this.funeralValue);
    },
    debtsAndLiabilitiesValue$() {
      return utils.numberToCurrency(this.debtsAndLiabilitiesValue);
    },
    assetsValue$() {
      return utils.numberToCurrency(this.assetsValue);
    },
    specialWishesValue$() {
      return utils.numberToCurrency(this.specialWishesValue);
    },
    estateValue$() {
      return utils.numberToCurrency(
        this.assetsValue +
          this.funeralValue -
          (this.debtsAndLiabilitiesValue + this.specialWishesValue)
      );
    },

    epfValue$() {
      return (this.assets && this.assets.moveable.epf) || `RM 0`;
    },
    tabungHajiValue$() {
      return (this.assets && this.assets.moveable.tabungHaji) || `RM 0`;
    },
    casaValue$() {
      return (this.assets && this.assets.moveable.casa) || `RM 0`;
    },
    invesmentValue$() {
      if (!this.assets) return `RM 0`;

      return utils.numberToCurrency(
        [
          utils.currencyToNumber(this.assets.moveable.unitTrust),
          utils.currencyToNumber(this.assets.moveable.collectibleItems),
          utils.currencyToNumber(this.assets.moveable.cyrpto),
          utils.currencyToNumber(this.assets.moveable.preciousMetals),
          utils.currencyToNumber(this.assets.moveable.shares),
          utils.currencyToNumber(this.assets.moveable.otherMoveableValue)
        ].reduce((acc, current, i) => acc + current, 0)
      );
    },

    houseValue$() {
      return (this.assets && this.assets.immovable.houseValue) || `RM 0`;
    },
    carValue$() {
      return (this.assets && this.assets.immovable.carValue) || `RM 0`;
    },
    landValue$() {
      return (this.assets && this.assets.immovable.landValue) || `RM 0`;
    },
    takafulValue$() {
      return (this.assets && this.assets.immovable.takafulValue) || `RM 0`;
    }
  }
});
</script>

<style lang="scss" scoped></style>
