<template>
  <div name="section" class="mt-12">
    <div
      name="section-header"
      class="text-center pt-2 pb-2 rounded-full	text-gray-50 font-bold uppercase mb-6"
    >
      Declaration
    </div>

    <div class="py-6 px-12">
      <label class="inline-block align-middle">
        <input
          v-model="truth"
          value="yes"
          name="declaration.truth"
          class="mr-6"
          required
          type="checkbox"
        />
        I hereby declare that the information provided in the above form is
        accurate
      </label>
      <p class="mt-6">(Please draw applicant signature below)</p>
      <canvas
        name="signature-pad"
        class="border border-gray-400 rounded"
        ref="signaturePad"
      >
        &nbsp;
      </canvas>
      <button
        type="button"
        name="clear-signature"
        class="block text-black bg-gray-400 px-6 py-1 border-1 rounded mt-1"
        @click="clearSignature"
      >
        Clear
      </button>
      <button
        type="button"
        class="text-black bg-yellow-400 font-bold px-12 py-2 border-1 rounded float-right mt-12"
        @click="$emit('proceed')"
      >
        Proceed
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SignaturePad from "signature_pad";

export default Vue.extend({
  props: ["value"],
  data() {
    return {
      truth: false,
      signature: undefined
    };
  },
  mounted() {
    const canvas = document.querySelector("canvas[name=signature-pad]");
    canvas.height = 200;

    // on window resize kene reinitialize
    this.$refs.signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgba(255, 255, 255, 0)",
      penColor: "rgb(0, 0, 0)",
      onEnd: () => {
        this.signature = this.$refs.signaturePad.toDataURL();
      }
    });
  },
  methods: {
    clearSignature() {
      this.$refs.signaturePad.clear();
    }
  },
  watch: {
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
