<template>
  <div>
    <label class="block mt-6" v-if="applicantGender === 'male'">
      <span class="mr-12">Number of Spouse</span>
      <InputRange
        v-model="noOfSpouse"
        :value="noOfSpouse"
        :min="0"
        :max="applicantGender === 'female' ? 1 : 4"
      />
    </label>

    <template v-for="(spouse, index) in spouses">
      <hr
        class="mt-6 mb-6 bg-top-c border-yellow-400"
        v-if="index > 0"
        v-bind:key="`A${index}`"
      />

      <div class="grid grid-cols-3 gap-4" v-bind:key="`B${index}`">
        <div class="col-span-2">
          <label class="block">
            <span class="required">Spouse's name</span>
            <input
              type="text"
              v-model="spouse.name"
              required
              class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="As per NRIC"
            />
          </label>
        </div>
        <div class="">
          <!-- <label class="block">
            <span class="required">Gender</span>
            <div class="mt-4 flex capitalize">
              {{ spouse.gender }}
              <input :name="`spouse.gender${index}`" required v-model="spouse.gender" class="mr-6" type="hidden" />
            </div>
          </label> -->
          <input
            :name="`spouse.gender${index}`"
            required
            v-model="spouse.gender"
            class="mr-6"
            type="hidden"
          />
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mt-6" v-bind:key="`C${index}`">
        <div class="">
          <label class="block mr-12">
            <span class="required block">Date of Birth</span>
            <DatePicker
              v-model="spouse.dob"
              format="DD/MM/YYYY"
              :disabled-date="isDateEnabled"
              @input="calculateAge"
              required
              class="mt-4  w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            />
          </label>
        </div>
        <div class="">
          <label class="block">
            <span class="required">Age</span>
            <input
              type="text"
              v-model="spouse.age"
              required
              class="mt-4 block w-12 rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              readonly
            />
          </label>
        </div>
        <div class="">
          <label class="block" v-if="applicantGender === 'male'">
            <span class="required">Is the spouse a housewife?</span>
            <div class="mt-4 flex">
              <label class="inline-block align-middle mr-6"
                ><input
                  :name="`spouse.isHouseWife${index}`"
                  required
                  v-model="spouse.isHouseWife"
                  value="yes"
                  class="mr-6"
                  type="radio"
                />
                Yes</label
              >
              <label class="inline-block align-middle"
                ><input
                  :name="`spouse.isHouseWife${index}`"
                  required
                  v-model="spouse.isHouseWife"
                  value="no"
                  class="mr-6"
                  type="radio"
                />
                No</label
              >
            </div>
          </label>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InputRange from "./InputRange";
dayjs.extend(customParseFormat);

const spouse = {
  name: "",
  gender: "",
  dob: "",
  age: "",
  isHouseWife: ""
};

function spouseFactory(applicantGender, currentSpouse) {
  return {
    ...(currentSpouse || spouse),
    gender: applicantGender === "male" ? "female" : "male"
  };
}

export default Vue.extend({
  props: ["value", "applicantGender", "maritalStatus"],
  data() {
    return {
      noOfSpouse: this.value.length,
      spouses: Array(this.value.length)
        .fill(spouse)
        .map(_ => spouseFactory(this.applicantGender))
    };
  },
  components: {
    InputRange,
    DatePicker
  },
  methods: {
    isDateEnabled(date) {
      const dob = dayjs(date);
      return dob.diff(dayjs(), "d") > -1;
    },
    calculateAge() {
      this.spouses = this.spouses.map(s => {
        let age = s.age;
        if (s.dob) {
          const dob = dayjs(s.dob);
          age = dayjs().diff(dob, "years");
        }

        return {
          ...s,
          age
        };
      });
    }
  },
  watch: {
    applicantGender(vm) {
      this.noOfSpouse = 1;
      this.spouses = this.spouses.map(s =>
        spouseFactory(this.applicantGender, s)
      );
      this.calculateAge();
    },
    noOfSpouse: {
      handler: function(val) {
        const diff = this.noOfSpouse - this.spouses.length;

        if (diff > 0) {
          let x = 0;
          while (x < diff) {
            this.spouses.push(spouseFactory(this.applicantGender));
            x++;
          }
        } else if (diff < 0) {
          let x = 0;
          while (x < Math.abs(diff)) {
            this.spouses.pop();
            x++;
          }
        }
      },
      deep: true
    },
    maritalStatus(vm) {
      if (vm === "single") {
        this.noOfSpouse = 0;
        this.spouses = [];
      }
    }
  },
  updated() {
    this.$emit("input", this.spouses);
  }
});
</script>

<style lang="scss" scoped></style>
