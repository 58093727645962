<template>
  <v-collapse-wrapper
    name="section"
    :active="true"
    class="mt-12"
    v-on:onStatusChange="this.$parent.toggleCaret"
  >
    <div
      name="section-header"
      v-collapse-toggle
      class="text-center pt-2 pb-2 rounded-full text-gray-50 font-bold uppercase mb-6"
    >
      Special Wishes
      <span class="caret text-xs">╲╱</span>
    </div>

    <section v-collapse-content>
      <p class="mb-6 font-bold">Types of special wishes</p>

      <div class="grid grid-cols-3 gap-4">
        <!-- Row -->
        <template v-if="hasAtLeastOneSpouse()">
          <template v-for="(cS, indexA) in cashToSpouse">
            <div class="" v-bind:key="`A${indexA}`">
              <span class="inline-block align-middle">
                To leave cash to spouse
              </span>
              <p
                v-if="indexA === 0"
                class="text-xs text-red-400 cursor-pointer"
                @click="addRecepientSpouse"
              >
                + add recipient
              </p>
              <p
                v-if="indexA > 0"
                class="text-xs text-red-400 cursor-pointer"
                @click="removeRecepientSpouse"
              >
                x remove recipient
              </p>
            </div>
            <div class="flex justify-end" v-bind:key="`B${indexA}`">
              <select
                class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none disabled:opacity-50"
                v-model="cS.name"
                :required="cS.value != ''"
              >
                <option value="">Please Select</option>
                <option
                  v-for="(spouse, i) in spouses"
                  v-bind:key="i"
                  :value="spouse.name"
                  >{{ spouse.name }}</option
                >
              </select>
            </div>
            <div class="" v-bind:key="`C${indexA}`">
              <input
                type="text"
                v-model="cS.value"
                :required="cS.name != ''"
                class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
                placeholder="Please state the amount (RM)"
                @input="toCurrency('cashToSpouse', indexA, $event)"
              />
            </div>
          </template>
        </template>

        <!-- Row -->
        <template v-if="hasAtLeastOneChild()">
          <template v-for="(cC, indexA) in cashToChildren">
            <div class="" v-bind:key="`AA${indexA}`">
              <span class="inline-block align-middle">
                To leave cash to children
              </span>
              <p
                v-if="indexA === 0"
                class="text-xs text-red-400 cursor-pointer"
                @click="addRecepientChildren"
              >
                + add recipient
              </p>
              <p
                v-if="indexA > 0"
                class="text-xs text-red-400 cursor-pointer"
                @click="removeRecepientChildren"
              >
                x remove recipient
              </p>
            </div>
            <div class="flex justify-end" v-bind:key="`BB${indexA}`">
              <select
                class="block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none disabled:opacity-50"
                v-model="cC.name"
                :required="cC.name != ''"
              >
                <option value="">Please Select</option>
                <option
                  v-for="(child, i) in children"
                  v-bind:key="i"
                  :value="child.name"
                  >{{ child.name }}</option
                >
              </select>
            </div>
            <div class="" v-bind:key="`CC${indexA}`">
              <input
                type="text"
                v-model="cC.value"
                :required="cC.name != ''"
                class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
                placeholder="Please state the amount (RM)"
                @input="toCurrency('cashToChildren', indexA, $event)"
              />
            </div>
          </template>
        </template>

        <!-- Row -->
        <div class="col-span-2">
          <span class="inline-block align-middle">
            All debts be paid immediately
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="debtsPaidImmediately"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('debtsPaidImmediately', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="inline-block align-middle">
            Sedeqah
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="sedeqah"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('sedeqah', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          Donation
        </div>
        <div class="">
          <input
            type="text"
            v-model="donation"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('donation', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          Waqaf
        </div>
        <div class="">
          <input
            type="text"
            v-model="waqaf"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('waqaf', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-2">
          <span class="flex items-center">
            <input
              v-model="otherSpecialWishes"
              class="mr-2 w-full disabled:opacity-50"
              type="text"
              placeholder="Others - Please state the types of immovable assets you own"
            />
          </span>
        </div>
        <div class="">
          <input
            type="text"
            v-model="otherSpecialWishesValue"
            class="block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0 disabled:opacity-50"
            placeholder="Please state the amount (RM)"
            @input="toCurrency('otherSpecialWishesValue', undefined, $event)"
          />
        </div>

        <!-- Row -->
        <div class="col-span-3"></div>
      </div>

      <hr class="mt-6 bg-top-c border-yellow-400" />

      <label class="mt-6 block">Remarks on special wishes</label>
      <textarea
        v-model="remarks"
        class="border-1 rounded w-full h-12"
        style="height: 154px"
      ></textarea>
    </section>
  </v-collapse-wrapper>
</template>

<script>
import Vue from "vue";
import utils from "./../utils";

const recepient = {
  name: "",
  value: ""
};

export default Vue.extend({
  props: ["value", "generalSection"],
  data() {
    return {
      // component data
      spouses: [],
      children: [],
      applicantGender: "",

      // form data
      cashToSpouse: [{ ...recepient }],
      cashToChildren: [{ ...recepient }],
      debtsPaidImmediately: "",
      sedeqah: "",
      donation: "",
      waqaf: "",
      otherSpecialWishes: "",
      otherSpecialWishesValue: "",
      remarks: ""
    };
  },
  methods: {
    hasAtLeastOneSpouse() {
      return (
        this.spouses?.length > 0 &&
        this.spouses.some(s => s.name && s.name !== "")
      );
    },
    hasAtLeastOneChild() {
      return (
        this.children?.length > 0 &&
        this.children.some(s => s.name && s.name !== "")
      );
    },
    addRecepientSpouse() {
      this.cashToSpouse.push({ ...recepient });
    },
    addRecepientChildren() {
      this.cashToChildren.push({ ...recepient });
    },
    removeRecepientSpouse() {
      this.cashToSpouse.pop();
    },
    removeRecepientChildren() {
      this.cashToChildren.pop();
    },

    toCurrency(position, index, e) {
      utils.toCurrency(this, position, index, e.target.value);
    }
  },
  watch: {
    generalSection: {
      handler(val) {
        this.spouses = [...val.spouses];
        this.children = [...val.children];
        this.applicantGender = val.children.gender;
        if (
          this.applicantGender === "female" &&
          this.spouses &&
          this.spouses.length > 0
        ) {
          this.cashToSpouse = this.cashToSpouse.map(s => ({
            ...s,
            name: this.spouses[0].name
          }));
        }
      },
      deep: true
    },
    $data: {
      handler: function(val, oldVal) {
        this.$emit("input", val);
      },
      deep: true
    }
  }
});
</script>

<style lang="scss" scoped></style>
