import numeral from "numeral";
import dot from "dot-prop";

// load a locale
numeral.register("locale", "my", {
  delimiters: {
    thousands: ",",
    decimal: "."
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "RM "
  }
});

numeral.locale("my");

export default {
  currencyToNumber: value => +numeral(value).value(),
  numberToCurrency: value => numeral(value).format("$0,0"),

  toCurrency: function(component, position, index, value) {
    if (index !== undefined) {
      const obj = dot.get(component, position);
      obj[index].value = this.numberToCurrency(value);
    } else {
      dot.set(component, position, this.numberToCurrency(value));
    }
  }
};
