<template>
  <div>
    <label class="block mt-6">
      <span class="mr-12">Number of Children</span>
      <InputRange v-model="noOfChildren" :min="0" />
    </label>

    <template v-for="(child, n) in children">
      <hr
        v-if="n > 0"
        class="mt-6 mb-6 bg-top-c border-yellow-400"
        v-bind:key="`A${n}`"
      />
      <div class="grid grid-cols-3 gap-4 mt-6" v-bind:key="`B${n}`">
        <div class="col-span-2">
          <label class="block">
            <span class="required">Child #{{ n + 1 }}</span>
            <input
              type="text"
              v-model="child.name"
              required
              class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              placeholder="Child Name"
            />
          </label>
        </div>
        <div class="">
          <label class="block">
            <span class="required">Relationship</span>
            <div class="mt-4 flex">
              <label class="inline-block align-middle mr-6"
                ><input
                  required
                  v-model="child.relationship"
                  value="son"
                  class="mr-6"
                  type="radio"
                />
                Son</label
              >
              <label class="inline-block align-middle"
                ><input
                  required
                  v-model="child.relationship"
                  value="daughter"
                  class="mr-6"
                  type="radio"
                />
                Daughter</label
              >
            </div>
          </label>
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mt-6" v-bind:key="`C${n}`">
        <div class="">
          <label class="block mr-12">
            <span class="required block">Date of Birth</span>
            <DatePicker
              v-model="child.dob"
              format="DD/MM/YYYY"
              :disabled-date="isDateEnabled"
              @input="calculateAge"
              required
              class="mt-4 block w-full rounded focus:border-gray-900 focus:bg-white focus:ring-0"
            />
          </label>
        </div>
        <div class="">
          <label class="block">
            <span class="required">Age</span>
            <input
              type="text"
              v-model="child.age"
              required
              class="mt-4 block w-12 rounded focus:border-gray-900 focus:bg-white focus:ring-0"
              readonly=""
            />
          </label>
        </div>
        <div class="">
          <label v-if="applicantGender === 'male'" class="block">
            <span class="required">Mother of the Child</span>
            <select
              v-model="child.parent"
              required
              class="mt-4 block border rounded h-10 pl-5 pr-10 focus:outline-none appearance-none"
            >
              <option selected value="">Please Select</option>
              <option
                v-for="(spouse, i) in spouses"
                v-bind:key="i"
                :value="spouse.name"
                >{{ spouse.name }}</option
              >
            </select>
          </label>
          <input
            v-if="applicantGender === 'female'"
            type="hidden"
            v-model="child.parent"
            name="parent"
            required
          />
        </div>
      </div>

      <div class="grid grid-cols-3 gap-4 mt-6" v-bind:key="`D${n}`">
        <div class="">
          <label class="block">
            <span class="required">Is this child working?</span>
            <div class="mt-4 flex">
              <label class="inline-block align-middle mr-6"
                ><input
                  required
                  :name="`child.isChildWorking${n}`"
                  v-model="child.isChildWorking"
                  value="yes"
                  class="mr-6"
                  type="radio"
                />
                Yes</label
              >
              <label class="inline-block align-middle"
                ><input
                  required
                  :name="`child.isChildWorking${n}`"
                  v-model="child.isChildWorking"
                  readonly
                  value="no"
                  class="mr-6"
                  type="radio"
                />
                No</label
              >
            </div>
          </label>
        </div>
        <div class="col-span-2">
          <label class="block">
            <span class="required">Is this child a "Special Needs Child"?</span>
            <div class="mt-4 flex">
              <label class="inline-block align-middle mr-6"
                ><input
                  required
                  :name="`child.isSpecialNeedsChild${n}`"
                  v-model="child.isSpecialNeedsChild"
                  readonly
                  value="yes"
                  class="mr-6"
                  type="radio"
                />
                Yes</label
              >
              <label class="inline-block align-middle"
                ><input
                  required
                  :name="`child.isSpecialNeedsChild${n}`"
                  v-model="child.isSpecialNeedsChild"
                  readonly
                  value="no"
                  class="mr-6"
                  type="radio"
                />
                No</label
              >
            </div>
          </label>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InputRange from "./InputRange";
dayjs.extend(customParseFormat);

const child = {
  name: "",
  relationship: "",
  dob: "",
  age: "",
  isChildWorking: "",
  isSpecialNeedsChild: "",
  parent: ""
};

function childFactory(applicantGender, applicantName, currentChild) {
  return {
    ...(currentChild || child),
    parent: applicantGender === "female" ? applicantName : ""
  };
}

export default Vue.extend({
  props: ["value", "spouses", "applicantGender", "applicantName"],
  data() {
    return {
      noOfChildren: this.value.length,
      children: Array(this.value.length)
        .fill(child)
        .map(_ => childFactory(this.applicantGender, this.applicantName))
    };
  },
  components: {
    InputRange,
    DatePicker
  },
  methods: {
    isDateEnabled(date) {
      const dob = dayjs(date);
      return dob.diff(dayjs(), "d") > -1;
    },
    calculateAge() {
      this.children = this.children.map(s => {
        let age = s.age;
        if (s.dob) {
          const dob = dayjs(s.dob);
          age = dayjs().diff(dob, "years");
        }

        return {
          ...s,
          age
        };
      });
    }
  },
  watch: {
    noOfChildren: function(val) {
      if (this.children.length < this.noOfChildren) {
        this.children.push(
          childFactory(this.applicantGender, this.applicantName)
        );
      } else {
        this.children.pop();
      }
    },
    applicantGender(vm) {
      this.children = this.children.map(c =>
        childFactory(this.applicantGender, this.applicantName, c)
      );
    },
    applicantName(vm) {
      this.children = this.children.map(c =>
        childFactory(this.applicantGender, this.applicantName, c)
      );
    }
  },
  updated() {
    this.$emit("input", this.children);
  }
});
</script>

<style lang="scss" scoped></style>
