<template>
  <div v-if="passSecurity === true" class="lg:container mx-auto px-12">
    <Nav />
    <form ref="iepForm" class="block" v-scroll-spy="{ offset: 150 }">
      <General v-model="general" id="general-section" />
      <Funeral v-model="funeral" id="funeral-section" />

      <DebtsLiabilities
        v-model="debtsAndLiabilities"
        id="debts-and-liabilities-section"
      />

      <Assets v-model="assets" id="assets-section" />

      <SpecialWishes
        v-model="specialWishes"
        id="special-wishes-section"
        :general-section="general"
      />

      <Summary
        v-model="summary"
        id="summary-section"
        :assets="assets"
        :funeral="funeral"
        :debtsAndLiabilities="debtsAndLiabilities"
        :specialWishes="specialWishes"
      />

      <Declaration
        v-model="declaration"
        id="declaration-section"
        v-on:proceed="validateForm"
      />
    </form>

    <sweet-modal
      ref="confirmModal"
      :enable-mobile-fullscreen="true"
      :hide-close-button="true"
      blocking
    >
      <p v-if="isProcessing" class="mt-6">Submitting form...</p>

      <template v-if="!isProcessing">
        <img :src="exclamation" class="m-auto" />
        <p class="mt-6">Are you sure you want to proceed?</p>
        <div class="flex justify-center mt-12">
          <button
            type="button"
            class="px-10 py-3 rounded w-36 border-2 border-yellow-400 mr-6"
            @click="() => this.$refs.confirmModal.close()"
          >
            Not yet
          </button>
          <button
            type="button"
            class="px-10 py-3 rounded w-36 bg-yellow-400"
            @click="submitForm"
          >
            Yes
          </button>
        </div>
      </template>
    </sweet-modal>

    <sweet-modal
      ref="successModal"
      :enable-mobile-fullscreen="true"
      :hide-close-button="true"
      blocking
    >
      <img :src="tick" class="m-auto" />
      <p class="mt-6">Thank you.</p>
      <p>Summary of profile has been sent to the emails.</p>
      <div class="flex justify-center mt-12">
        <button
          type="button"
          class="px-10 py-3 rounded w-56 bg-yellow-400"
          @click="reloadPage"
        >
          Create New Profile
        </button>
      </div>
    </sweet-modal>

    <sweet-modal ref="errorModal" icon="error" :enable-mobile-fullscreen="true">
      Fail to submit form.
    </sweet-modal>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import downloadjs from "downloadjs";
import { SweetModal } from "sweet-modal-vue";
import VueScrollTo from "vue-scrollto";
import VueCollapse from "vue2-collapse";
import Scrollspy, { Easing } from "vue2-scrollspy";
import Nav from "./components/Nav.vue";
import General from "./sections/General.vue";
import Funeral from "./sections/Funeral.vue";
import DebtsLiabilities from "./sections/DebtsLiabilities.vue";
import Assets from "./sections/Assets.vue";
import SpecialWishes from "./sections/SpecialWishes.vue";
import Summary from "./sections/Summary.vue";
import Declaration from "./sections/Declaration.vue";
import exclamation from "./assets/exclamation.svg";
import tick from "./assets/tick.svg";

Vue.use(VueScrollTo, {
  offset: -135
});
Vue.use(VueCollapse);
Vue.use(Scrollspy, {
  easing: Easing.Cubic.In
});

export default Vue.extend({
  data() {
    return {
      // component data
      exclamation,
      tick,
      isProcessing: false,
      passSecurity: false,

      // form data
      general: undefined,
      funeral: undefined,
      debtsAndLiabilities: undefined,
      assets: undefined,
      specialWishes: undefined,
      summary: undefined,
      declaration: undefined
    };
  },
  components: {
    Nav,
    General,
    Funeral,
    DebtsLiabilities,
    Assets,
    SpecialWishes,
    Summary,
    Declaration,
    SweetModal
  },
  methods: {
    validateForm(e) {
      const isValid = this.$refs.iepForm.reportValidity();

      if (!isValid) {
        window.scroll(0, window.scrollY - 170);
        return;
      }

      /**
       * @TODO: Ensure that the validity of the signature can be checked with reportValidity()
       */
      if (!this.declaration.signature) {
        alert(
          "Ensure that applicant has signed the form at the bottom of the page."
        );
        return;
      }
      this.$refs.confirmModal.open();
    },

    submitForm() {
      const instance = axios.create({
        baseURL: process.env.FORM_PROCESSOR_URL || "//localhost:8080",
        timeout: 60000
      });

      this.isProcessing = true;
      instance
        .post(
          "/",
          {
            client_email: this.$data.summary.customerEmail,
            agent_email: this.$data.summary.agentEmail,
            form_data: JSON.parse(JSON.stringify(this.$data))
          },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf"
            }
          }
        )
        .then(response => {
          const content = response.headers["content-type"];
          downloadjs(
            response.data,
            `${this.$data.general.name}-iep-profile.pdf`,
            content
          );
          this.isProcessing = false;
          this.$refs.confirmModal.close();
          this.$refs.successModal.open();
        })
        .catch(error => {
          console.log(error);
          this.isProcessing = false;
          this.$refs.confirmModal.close();
          this.$refs.errorModal.open();
        });
    },

    resetForm() {},

    toggleCaret: function(vm) {
      const $caret = vm.vm.nodes.toggle.querySelector(".caret");
      if ($caret) {
        $caret.classList.toggle("closed");
      }
    },

    reloadPage() {
      window.location.reload();
    }
  },
  watch: {
    general: {
      handler: function(val) {
        console.log("general", val);
        const funeralSectionComponent = this.$children.find(
          c => c.$el.id === "funeral-section"
        );
        if (funeralSectionComponent) {
          this.funeral = {
            ...funeralSectionComponent.$data
          };
        }
      },
      deep: true
    },
    funeral: {
      handler: val => {
        console.log("funeral", val);
      },
      deep: true
    },
    debtsAndLiabilities: {
      handler: val => {
        console.log("debtsAndLiabilities", val);
      },
      deep: true
    },
    assets: {
      handler: val => {
        console.log("assets", val);
      },
      deep: true
    },
    specialWishes: {
      handler: val => {
        console.log("specialWishes", val);
      },
      deep: true
    },
    summary: {
      handler: val => {
        console.log("summary", val);
      },
      deep: true
    },
    declaration: {
      handler: val => {
        console.log("declaration", val);
      },
      deep: true
    }
  },
  mounted() {
    const confirmModal = prompt("Please enter password");
    if (confirmModal === "2021iepbank") {
      this.passSecurity = true;
    } else {
      window.reload();
    }
  }
});
</script>

<style lang="scss" scoped></style>
