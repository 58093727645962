import * as Sentry from "@sentry/vue";
import Vue from "vue";
import App from "./App.vue";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.SENTRY_DSN,
    logErrors: true
  });

  console.log = () => {};
}

new Vue({ render: createElement => createElement(App) }).$mount("#app");
